import { adminsApi } from '@/api';
import { noData, textRowsPerPage } from '@/constants';
import { useApiPagination } from '@/hooks';
import { calcAge, getMatureGenderText, getTextChildGender } from '@/utils';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Checkbox,
  Collapse,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';

import { NotiCampaignUserFilter } from './NotiCampaignUserFilter';

const StyledNotiCampaignUserTable = styled.div`
  position: relative;
`;

export const deleteModalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: "36px",
};

function UserRow(props: { row: any; handleClick: any; selectedItem: any }) {
  const { row, handleClick, selectedItem } = props;

  //--- State
  const [open, setOpen] = useState(false);

  //--- Select row
  const isSelected = (name: string) => selectedItem?.indexOf(name) !== -1;
  const isItemSelected = isSelected(row.userId);
  const labelId = `enhanced-table-checkbox-${row.userId}`;

  return (
    <>
      <TableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.userId}
        selected={isItemSelected}
      >
        <TableCell id={labelId}>{row.id}</TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <span className="whitespace-nowrap">{row.name}</span>
        </TableCell>
        <TableCell>{moment(row.birthday).format("DD/MM/yyyy")}</TableCell>
        <TableCell>
          <span className="whitespace-nowrap">
            {getMatureGenderText(row.gender)}
          </span>
        </TableCell>
        <TableCell>{row.email}</TableCell>
        <TableCell>{moment(row.createdAt).format("DD/MM/yyyy")}</TableCell>
        <TableCell>{row.address}</TableCell>
        <TableCell padding="checkbox" align="center">
          <Checkbox
            color="primary"
            checked={isItemSelected}
            inputProps={{
              "aria-labelledby": labelId,
            }}
            onClick={(event) => handleClick(event, row.userId)}
          />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div className="m-6">
              <h6 className="font-medium mb-4">子ども</h6>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <span className="color-primary">ニックネーム</span>
                    </TableCell>
                    <TableCell>
                      <span className="color-primary">子供の性別</span>
                    </TableCell>
                    <TableCell>
                      <span className="color-primary">子供の年齢</span>
                    </TableCell>
                    <TableCell>
                      <span className="color-primary">子供の状態</span>
                    </TableCell>
                    <TableCell>
                      <span className="color-primary">受診の記録の診断名</span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.babies?.length > 0 &&
                    row.babies.map((babyItem: any) => (
                      <TableRow key={"baby_" + babyItem.id}>
                        <TableCell>{babyItem.name}</TableCell>
                        <TableCell>
                          {getTextChildGender(babyItem.gender)}
                        </TableCell>
                        <TableCell>{calcAge(babyItem.birthday)}</TableCell>
                        <TableCell>子ども</TableCell>
                        <TableCell>{babyItem.illNameList.join(", ")}</TableCell>
                      </TableRow>
                    ))}

                  {row.fetuses?.length > 0 &&
                    row.fetuses.map((fetusItem: any) => (
                      <TableRow key={"fetus_" + fetusItem.id}>
                        <TableCell>{fetusItem.name}</TableCell>
                        <TableCell>
                          {getTextChildGender(fetusItem.gender)}
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell>妊娠中</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    ))}

                  {row.babies?.length === 0 && row.fetuses?.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={5}>
                        <div className="text-center font-bold">{noData}</div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

type INotiCampaignUserTableProps = {
  userListId: any;
  setUserListId: any;
};

export const NotiCampaignUserTable = (props: INotiCampaignUserTableProps) => {
  //--- State
  const [motherBirthdayStartDate, setMotherBirthdayStartDate] = useState("");
  const [motherBirthdayEndDate, setMotherBirthdayEndDate] = useState("");
  const [motherGender, setMotherGender] = useState("");
  const [motherEmail, setMotherEmail] = useState("");
  const [motherRegisterStartDate, setMotherRegisterStartDate] = useState("");
  const [motherRegisterEndDate, setMotherRegisterEndDate] = useState("");
  const [motherAddress, setMotherAddress] = useState("");
  const [babyGender, setBabyGender] = useState("");
  const [babyAgeStart, setBabyAgeStart] = useState("");
  const [babyAgeEnd, setBabyAgeEnd] = useState("");
  const [babyStatus, setBabyStatus] = useState("");
  const [diagnosisList, setDiagnosisList] = useState<any[]>([]);
  const [isPaginate, setPaginate] = useState<boolean>(false);

  const [isSelectAllUser, setIsSelectAllUser] = useState<boolean>(false);

  //--- Select item
  const [selectedCheckbox, setSelectedCheckbox] = useState<readonly any[]>([]);

  //--- Get user list
  const {
    data: userList,
    isFetching,
    refetch: refetchUserList,
  } = useQuery({
    queryKey: "GET_DIAGNOSIS_LIST_DRUG",
    queryFn: () => {
      return adminsApi.adminFilterGet({
        page: isPaginate ? apiPagi.page : 1,
        itemsPerPage: apiPagi.itemsPerPage,
        orderBy: "id",
        orderType: "desc",
        motherBirthStart: motherBirthdayStartDate,
        motherBirthEnd: motherBirthdayEndDate,
        motherGender: motherGender === "ALL" ? "" : motherGender,
        email: motherEmail,
        createStart: motherRegisterStartDate,
        createEnd: motherRegisterEndDate,
        address: motherAddress,
        babyGender: babyGender === "ALL" ? "" : babyGender,
        babyAgeStart: babyAgeStart,
        babyAgeEnd: babyAgeEnd,
        babyStatus: babyStatus,
        diagnosis: diagnosisList?.map((item: any) => item.id)?.join(",") || "",
      });
    },
    onSuccess: (data) => {
      apiPagi.setTotalItems(data.meta.totalItems);
      handleCheckAllUser()
      if (isPaginate) setPaginate(false);
    },
    initialData: { data: [] },
  });

  const apiPagi = useApiPagination({
    onPageChange: () => refetchUserList()
  });

  const handleFilterUser = async () => {
    apiPagi.setPage(1);
    setSelectedCheckbox(selectedCheckbox);
    props.setUserListId(selectedCheckbox);
    refetchUserList();
  }

  const handleClickCheckbox = async (
    event: React.MouseEvent<unknown>,
    name: string
  ) => {
    let newSelected: string[] = [...selectedCheckbox];
    const selectedIndex = selectedCheckbox.indexOf(name);

    if (selectedIndex === -1) {
      newSelected.push(name);
    } else {
      newSelected = newSelected.filter(item => item !== name);
    }

    setSelectedCheckbox(newSelected);
    props.setUserListId(newSelected);

    const totalUsers = await fetchAllUsersPaginated();
    const isAllSelected = totalUsers.every(userId => newSelected.includes(userId));

    setIsSelectAllUser(isAllSelected);
  };

  const fetchAllUsersPaginated = async (): Promise<string[]> => {
    let allUserIds: string[] = [];
    let currentPage = 1;
    const itemsPerPage = 100;
    let hasNextPage = true;

    try {
      while (hasNextPage) {
        const response = await adminsApi.adminFilterGet({
          page: currentPage,
          itemsPerPage: itemsPerPage,
          orderBy: "id",
          orderType: "desc",
          motherBirthStart: motherBirthdayStartDate,
          motherBirthEnd: motherBirthdayEndDate,
          motherGender: motherGender === "ALL" ? "" : motherGender,
          email: motherEmail,
          createStart: motherRegisterStartDate,
          createEnd: motherRegisterEndDate,
          address: motherAddress,
          babyGender: babyGender === "ALL" ? "" : babyGender,
          babyAgeStart: babyAgeStart,
          babyAgeEnd: babyAgeEnd,
          babyStatus: babyStatus,
          diagnosis: diagnosisList?.map((item: any) => item.id)?.join(",") || "",
        });

        if (response.data.length > 0) {
          allUserIds = allUserIds.concat(
            response.data.map((user: any) => user.userId)
          );
        }

        const totalPages = Math.ceil(response.meta.totalItems / itemsPerPage);
        hasNextPage = currentPage < totalPages;

        if (hasNextPage) {
          currentPage++;
        }
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }

    return allUserIds;
  };


  const handleSelectAllUser = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;

    setIsSelectAllUser(isChecked);

    if (isChecked) {
      const allUserIds = await fetchAllUsersPaginated();

      setSelectedCheckbox(allUserIds);
      props.setUserListId(allUserIds);
    } else {
      setSelectedCheckbox([]);
      props.setUserListId([]);
    }
  };

  const handleCheckAllUser = async () => {
    const hasFilter =
      motherBirthdayStartDate ||
      motherBirthdayEndDate ||
      motherGender ||
      motherEmail ||
      motherRegisterStartDate ||
      motherRegisterEndDate ||
      motherAddress ||
      babyGender ||
      babyAgeStart ||
      babyAgeEnd ||
      babyStatus ||
      (diagnosisList?.length > 0);

    if (hasFilter) {
      const filteredUserIds = await fetchAllUsersPaginated();
      const isExisted = filteredUserIds.some((userId) =>
        selectedCheckbox.includes(userId)
      );
      const result = filteredUserIds.filter((userId) =>
        selectedCheckbox.includes(userId)
      );

      if (result.length === filteredUserIds.length) {
        setIsSelectAllUser(isExisted);
      } else {
        setIsSelectAllUser(false);
      }
    } else {
      const allUserIds = await fetchAllUsersPaginated();
      const isExisted = allUserIds.some((userId) =>
        selectedCheckbox.includes(userId)
      );
      const result = allUserIds.filter((userId) =>
        selectedCheckbox.includes(userId)
      );

      if (result.length === allUserIds.length) {
        setIsSelectAllUser(isExisted);
      } else {
        setIsSelectAllUser(false);
      }
    }
  };

  useEffect(() => {
    apiPagi.setPage(1);
    refetchUserList();

    if (props.userListId?.length > 0) {
      setSelectedCheckbox(props.userListId?.map((item: any) => Number(item)));
    } else {
      setSelectedCheckbox([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledNotiCampaignUserTable>
      <NotiCampaignUserFilter
        setMotherBirthdayStartDate={setMotherBirthdayStartDate}
        setMotherBirthdayEndDate={setMotherBirthdayEndDate}
        setMotherGender={setMotherGender}
        setMotherEmail={setMotherEmail}
        setMotherRegisterStartDate={setMotherRegisterStartDate}
        setMotherRegisterEndDate={setMotherRegisterEndDate}
        setMotherAddress={setMotherAddress}
        setBabyGender={setBabyGender}
        setBabyAgeStart={setBabyAgeStart}
        setBabyAgeEnd={setBabyAgeEnd}
        setBabyStatus={setBabyStatus}
        setDiagnosisList={setDiagnosisList}
        onClickSearch={handleFilterUser}
      />

      {isFetching && (
        <div className="absolute w-full">
          <LinearProgress />
        </div>
      )}

      <TableContainer component={Paper} className="mb-8">
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>
                <span className="color-primary">ID</span>
              </TableCell>
              <TableCell />
              <TableCell>
                <span className="color-primary">ユーザー</span>
              </TableCell>
              <TableCell>
                <span className="color-primary">生年月日</span>
              </TableCell>
              <TableCell>
                <span className="color-primary">性別</span>
              </TableCell>
              <TableCell>
                <span className="color-primary">メールアドレス</span>
              </TableCell>
              <TableCell>
                <span className="color-primary">会員登録日</span>
              </TableCell>
              <TableCell>
                <span className="color-primary">お住いの地域</span>
              </TableCell>
              <TableCell>
                <Checkbox
                  color="primary"
                  checked={isSelectAllUser}
                  onChange={handleSelectAllUser}
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userList?.data?.length > 0 &&
              userList?.data?.map((item: any) => (
                <UserRow
                  key={item.id}
                  row={item}
                  handleClick={handleClickCheckbox}
                  selectedItem={selectedCheckbox}
                />
              ))}

            {userList?.data?.length === 0 && (
              <TableRow>
                <TableCell colSpan={9}>
                  <div className="text-center font-bold">{noData}</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        className="custom-pagination"
        component="div"
        count={apiPagi.totalItems}
        rowsPerPage={apiPagi.itemsPerPage}
        page={apiPagi.page - 1}
        labelRowsPerPage={textRowsPerPage}
        onPageChange={(e, page) => {
          setPaginate(true);
          apiPagi.setPage(page + 1);

        }}
        onRowsPerPageChange={(e) => {
          apiPagi.setItemsPerPage(+e.target.value);
        }}
        labelDisplayedRows={(p) => `${p.from}-${p.to} / ${p.count}件`}
      />
    </StyledNotiCampaignUserTable>
  );
};
